<template>
  <v-app>
    <div class="row">
      <div class="col-12">
      <div class="card">
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4>Manage exams</h4>
            <div class="breadcrumb-sub-header">
              <router-link to="/dashboard">Dashboard</router-link>
              \  Exams
            </div>
          </div>
          <div class="breadcrumb-right" v-if="checkIsAccessible('exam', 'create')">
            <div class="card-toolbar">
              <v-btn @click="openDialog()" class="mt-4 btn btn-primary" style="color: #fff">
                <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                Add new exam
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <div class="row">
              <v-col cols="12" md="1">
                <v-select
                    class="form-control"
                    outlined clearable
                    v-model="search.academic_year"
                    dense
                    :items="academicYears"
                    item-text="title"
                    item-value="id"
                    label="Batch">
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                    class="form-control"
                    outlined clearable
                    :items="programs"
                    v-model="search.program_id"
                    @change="getGrades"
                    dense item-text="title" item-value="id"
                    label="Program">
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                    class="form-control"
                    outlined clearable
                    :items="gradesLevels"
                    v-model="search.grade_id"
                    dense
                    item-text="title"
                    item-value="id"
                    label="Semester/year"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="1">
                <v-select
                    class="form-control"
                    outlined clearable
                    :items="types"
                    v-model="search.type"
                    dense
                    item-text="text"
                    item-value="value"
                    label="Type"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="search.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="search.start_date"
                        label="Search by date"
                        outlined dense clearable
                        persistent-hint
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="search.start_date"
                      no-title
                      @input="menu2 = false"
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(search.start_date)"
                    >
                      OK
                    </v-btn>

                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                    class="form-control"
                    outlined
                    :items="statuses"
                    v-model="search.status"
                    dense clearable
                    item-text="text"
                    item-value="value"
                    label="Status"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                    @click.prevent="getExams()"
                    class="btn btn-primary w-35 btn-search "
                    :loading="isBusy">
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </div>
          </div>
          <div class="">
            <v-skeleton-loader
                type="table-thead"
                v-if="loading"
            >
            </v-skeleton-loader>

            <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
            >
            </v-skeleton-loader>
            <table class="table"   v-if="!loading">
              <thead>
              <tr class="px-3">
                <th class="px-3 wrap-column">Title</th>
                <th class="px-3 wrap-column">Academic info</th>
                <th class="px-3 wrap-column">Start date</th>
                <th class="px-3 wrap-column">Exam status</th>
                <th class="px-3 wrap-column">Result status</th>
                <th class="pr-3 text-center"></th>
              </tr>
              </thead>
              <tbody>
                <tr v-show="exams.length > 0" v-for="exam in exams" :key="exam.id">
                  <td class="px-2 wrap-column">
                    <a href="#!"  @click="editExam(exam)" class="mb-1">{{ exam.title }}</a> |
                    <a href="#!" @click="editExam(exam)" class="mb-1">
                      <b title="Exam unique identifier"> {{ exam.unique_no ? exam.unique_no : '' }} </b>
                      <span class="badge-dot" v-bind:class="{ 'bg-color-green': exam.is_active, 'bg-color-red': !exam.is_active }">
                        </span><br />
                    </a> {{ exam.type.toUpperCase() }} |
                    <span class="count_number_badge" title="Total students">{{ exam.total_student }}</span>
                  </td>
                  <td class="px-2 wrap-column">
                    <simplebold>{{ exam.program_title }}</simplebold> |
                    <simplebold>{{ exam.semester_title }}</simplebold> |
                    <simplebold>{{ exam.academic_year_title }}</simplebold>
                  </td>
                  <td class="px-2 wrap-column">
                    <simplebold>{{ exam.formatted_start_date }}</simplebold>
                  </td>
                  <td class="wrap-column px-3">
                    <span class="badge ml-2 mb-2"
                          :class="exam.exam_status ? exam.exam_status =='Exam completed'?'badge-success':'badge-success' :'badge-warning'">{{
                        exam.exam_status ? exam.exam_status.toUpperCase() : 'NOT COMPLETE'
                      }}</span>
                  </td>
                  <td class="wrap-column px-3">
                    <span class="badge ml-2"
                          :class="exam.result_status ? exam.result_status =='Result published'?'badge-success':'badge-success' :'badge-danger'">{{
                        exam.result_status ? exam.result_status.toUpperCase() : "NOT PUBLISHED"
                      }}</span>
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item" >
                            <a @click="editExam(exam)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>




                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-show="exams.length==0">
                  <td colspan="6" class="text-center">
                   <strong>
                     No data available to display.
                   </strong>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-pagination
                v-if="exams.length > 0"
                class="pull-right mt-7"
                @input="getExams"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
            ></b-pagination>
          </div>
<!--          <div class="col-12" v-if="exams && exams.length>0">
            <v-expansion-panels focusable>
              <v-expansion-panel
                  v-for="(exam, index) of exams" :key="index"
              >
                <v-expansion-panel-header>
                  &lt;!&ndash;                  <i class="fas fa-circle"&ndash;&gt;
                  &lt;!&ndash;                     :class="academicClass.is_active?'dot-active':'dot-inactive'"></i>&ndash;&gt;
                  <router-link :to="{ name:'exams-summary',params:{exam_id:exam.id}}">
                    {{ exam.title }} | {{ exam.unique_no ? exam.unique_no : '' }} | {{ exam.semester_title }} |
                    {{ exam.academic_year_title }} | {{ exam.program_title }}
                    <span class="badge badge-primary btn-circle">{{ exam.total_student }}</span>
                    <span class="badge ml-2 mb-2"
                          :class="exam.exam_status ? exam.exam_status =='Exam completed'?'badge-success':'badge-warning' :'badge-warning'">{{
                        exam.exam_status ? exam.exam_status.toUpperCase() : 'Exam Not Completed'
                      }}</span>
                    <span class="badge ml-2"
                          :class="exam.result_status ? exam.result_status =='Result Published'?'badge-success':'badge-danger' :'badge-danger'">{{
                        exam.result_status ? exam.result_status.toUpperCase() : "Result Not Published"
                      }}</span>
                    <v-progress-circular class="ml-3" v-if="exam.pending_jobs"
                                         :size="25"
                                         indeterminate
                                         color="black"
                    ></v-progress-circular>
                  </router-link>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="p-10">
                  &lt;!&ndash;                  <p>{{ exam.title }}</p>&ndash;&gt;
                  <p class="mt-2"> {{ exam.program_title }} - {{ exam.semester_title }} - {{
                      exam.academic_year_title
                    }}</p><br>
                  &lt;!&ndash;                    <b>Subject Code:</b> {{ exam.subject_code }}<br>&ndash;&gt;
                  <b>Unique Identifier:</b> {{ exam && exam.unique_no ? exam.unique_no : 'N/A' }}<br>
                  <b>Total Students:</b> <span class="badge badge-primary btn-circle">{{
                    exam.total_student
                  }}</span><br>
                  <b>Type:</b> {{ exam.type.toUpperCase() }}<br>
                  <b>Start Date:</b> {{ exam.formatted_start_date }}<br>
                  <b>Exam Status</b> <span class="badge mb-2"
                                           :class="exam.exam_status ? exam.exam_status =='completed'?'badge-success':'badge-warning' :'badge-warning'">{{
                    exam.exam_status ? exam.exam_status.toUpperCase() : 'Not Completed'
                  }}</span><br>
                  <b>Result Status</b> <span class="badge"
                                             :class="exam.result_status ? exam.result_status =='published'?'badge-success':'badge-danger' :'badge-danger'">{{
                    exam.result_status ? exam.result_status.toUpperCase() : "Pending"
                  }}</span><br>
                  <router-link class="btn btn-primary" :to="{ name:'exams-summary',params:{exam_id:exam.id}}">
                    Manage
                  </router-link>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="col-12 text-right">
              <b-pagination
                  @input="getAcademicYears"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
              &lt;!&ndash;  &ndash;&gt;
            </div>
            &lt;!&ndash;            <div class="row">&ndash;&gt;
            &lt;!&ndash;              <div class="col-xl-3 col-md-3 col-sm-12" v-for="(exam, index) of exams" :key="index">&ndash;&gt;
            &lt;!&ndash;                <b-card style="max-width: 25rem;"&ndash;&gt;
            &lt;!&ndash;                        src="https://picsum.photos/400/200/?image=180"&ndash;&gt;
            &lt;!&ndash;                        img-alt="Image">&ndash;&gt;
            &lt;!&ndash;                  <template v-slot:header>&ndash;&gt;
            &lt;!&ndash;                    <router-link :to="{ name:'exams-summary',params:{exam_id:exam.id}}">&ndash;&gt;
            &lt;!&ndash;                    <h6 class="mb-0">{{ exam.title }}&ndash;&gt;
            &lt;!&ndash;                    </h6>&ndash;&gt;
            &lt;!&ndash;                    </router-link>&ndash;&gt;
            &lt;!&ndash;                  </template>&ndash;&gt;
            &lt;!&ndash;                  <b-card-text>&ndash;&gt;
            &lt;!&ndash;                    {{ exam.program_title }} - {{ exam.semester_title }} - {{ exam.academic_year_title }}<br>&ndash;&gt;
            &lt;!&ndash;&lt;!&ndash;                    <b>Subject Code:</b> {{ exam.subject_code }}<br>&ndash;&gt;&ndash;&gt;
            &lt;!&ndash;                    <b>Unique Identifier:</b> {{ exam && exam.unique_no? exam.unique_no:'N/A' }}<br>&ndash;&gt;
            &lt;!&ndash;                    <b>Total Students:</b> <span class="badge badge-primary btn-circle">{{ exam.total_student }}</span><br>&ndash;&gt;
            &lt;!&ndash;                    <b>Type:</b> {{ exam.type.toUpperCase() }}<br>&ndash;&gt;
            &lt;!&ndash;                    <b>Start Date:</b> {{ exam.formatted_start_date }}<br>&ndash;&gt;
            &lt;!&ndash;                    <b>Exam Status</b>  <span class="badge mb-2" :class="exam.exam_status ? exam.exam_status =='completed'?'badge-success':'badge-warning' :'badge-warning'">{{exam.exam_status ? exam.exam_status.toUpperCase() : 'Not Completed' }}</span><br>&ndash;&gt;
            &lt;!&ndash;                    <b>Result Status</b>  <span class="badge" :class="exam.result_status ? exam.result_status =='published'?'badge-success':'badge-danger' :'badge-danger'">{{exam.result_status ? exam.result_status.toUpperCase() : "Pending" }}</span><br>&ndash;&gt;
            &lt;!&ndash;                  </b-card-text>&ndash;&gt;
            &lt;!&ndash;&lt;!&ndash;                  <b-card-text>&ndash;&gt;&ndash;&gt;
            &lt;!&ndash;&lt;!&ndash;                    <a href="#" @click="openDialog(exam)">Edit</a> |&ndash;&gt;&ndash;&gt;
            &lt;!&ndash;&lt;!&ndash;                    <a href="#" @click="deleteExam(exam.id)">Delete</a>&ndash;&gt;&ndash;&gt;
            &lt;!&ndash;&lt;!&ndash;                  </b-card-text>&ndash;&gt;&ndash;&gt;

            &lt;!&ndash;                  <template v-slot:footer>&ndash;&gt;
            &lt;!&ndash;                    <router-link class="btn btn-primary" :to="{ name:'exams-summary',params:{exam_id:exam.id}}">&ndash;&gt;
            &lt;!&ndash;                      Manage&ndash;&gt;
            &lt;!&ndash;                    </router-link>&ndash;&gt;
            &lt;!&ndash;                  </template>&ndash;&gt;

            &lt;!&ndash;                </b-card>&ndash;&gt;
            &lt;!&ndash;              </div>&ndash;&gt;
            &lt;!&ndash;            </div>&ndash;&gt;
          </div>
          <div class="col-12" v-else>
            <div class="col-md-12 text-center">
              <v-card class="p-20">
                <img src="@/assets/media/images/no-data-found.png">
                <h5 class="mt-10">No exams are available</h5>
              </v-card>

            </div>
          </div>-->
        </div>
      </div>

      <create-and-update ref="exam" @refresh="getExams"></create-and-update>
      <assign-courses ref="assignCourse" @refresh="getExams"></assign-courses>
      <courses-timetable ref="courseTimetable" @refresh="getExams"></courses-timetable>
    </div>
    </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import AssignCourses from "./AssignCourses";
import CoursesTimetable from "./CoursesTimetable";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const examService = new ExamService();
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();

export default {
  name: "academic-classes",
  components: {
    CreateAndUpdate,
    AssignCourses,
    CoursesTimetable
  },
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      },
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ],

      statuses: [
        {
          text: 'All',
          value: 'all',
        }, {
          text: 'Upcoming',
          value: 'upcoming',
        },
        {
          text: 'Past',
          value: 'past',
        },
        {
          text: 'Exam Completed',
          value: 'completed',
        },
        {
          text: 'Exam Cancelled',
          value: 'cancelled',
        },
        {
          text: 'Exam Terminated',
          value: 'terminated',
        },
        {
          text: 'Exam Taken',
          value: 'taken',
        },
        {
          text: 'Result Publish',
          value: 'publish',
        },
        {
          text: 'Result Pending',
          value: 'pending',
        },

      ],
      academicYears: [],
      exam: null,
      programs: [],
      gradesLevels: [],
      exams: [],
      academic_id: null,
      previousExamsDialog: false,
      menu2: false,
      menu: false,
      page: null,
      total: null,
      isBusy: false,
      perPage: null,
      loading:false,
    };
  },
  mounted() {
    this.getExams();
    this.getAcademicYears();
    this.getPrograms();

  },
  methods: {
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {

      academicYearService
          .paginate()
          .then(response => {
              this.academicYears = response.data.data;
          }).catch(() => {

           });

    },
    getAcademicClasses() {
      academicClassService.paginate().then(response => {
        // this.exams = response.data.data;
       /* this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;*/
      });
    },
    reset() {
      this.search = {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      }
      this.getExams();
    },
    getExams() {
     /* this.$bus.emit('toggleLoader');*/
      this.isBusy = true;
      this.loading = true;
      examService
          .paginate(this.search,this.page)
          .then(response => {
              this.exams = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
          }).catch((err) => {
              // console.log(err)
          }).finally(() => {
             /* this.$bus.emit('toggleLoader');*/
              this.isBusy = false;
              this.loading = false;
          });
    },
    openDialog(exam) {
      this.$refs["exam"].showModal(exam);
    },
    assignCourse(exam) {
      this.$refs["assignCourse"].showModal(exam);
    },
    manageCourseTimeTable(exam) {
      this.$refs["courseTimetable"].showModal(exam);
    },
    editExam(item){
      this.$router.push(
          {
            name: 'exams-summary',
            params: {exam_id: item.id}
          }
      )
    },
    deleteExam(id) {
      this.$confirm({
        message: `Do you really want ot delete this exam?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.delete(id).then(response => {
              this.$snotify.success("Information deleted");
              this.getAcademicClasses();
            });
          }
        }
      });
    }
  }
};
</script>
